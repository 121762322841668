@tailwind base;
@tailwind components;
@tailwind utilities;

face {
  font-family: "ABC Regular";
  font-style: normal;
  font-weight: normal;
  src: local("ABC Regular"),
    url("../src/styles/fonts/ABCWhyteInktrap-Regular.otf") format("opentype");
}

@font-face {
  font-family: "CosiTimes";
  font-style: normal;
  font-weight: normal;
  src: local("CosiTimes"),
    url("../src/styles/fonts/CosiTimes-Roman.otf") format("opentype");
}

@font-face {
  font-family: "Karla ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Karla-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Karla SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Karla-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-Bold.ttf") format("truetype");
}

body {
  font-family: ABC Light, sans-serif;
  scrollbar-gutter: stable;
  color: white;
}
th {
  font-family: Karla, sans-serif;
}

.abc-thin {
  font-family: ABC Thin, sans-serif;
}
.abc-exl {
  font-family: ABC ExtraLight, sans-serif;
}
.abc-light {
  font-family: ABC Light, sans-serif;
}
.abc-regular {
  font-family: ABC Regular, sans-serif;
}
.abc-bold {
  font-family: ABC-Bold, sans-serif;
}
.cositimes {
  font-family: CosiTimes, sans-serif;
}
.karla-exl {
  font-family: Karla ExtraLight, sans-serif;
}
.karla-light {
  font-family: Karla Light, sans-serif;
}
.karla {
  font-family: Karla, sans-serif;
}
.karla-medium {
  font-family: Karla-Medium, sans-serif;
}
.karla-semibold {
  font-family: Karla SemiBold, sans-serif;
}
.karla-bold {
  font-family: Karla-Bold, sans-serif;
}

tr {
  font-family: Karla, sans-serif;
}

.slick-arrow::before {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}

.react-pdf__Page__textContent {
  display: none;
}

.lock-scroll {
  overflow: hidden;
}